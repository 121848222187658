<template>
  <div v-if="localItem && localItem.quantity">
    <div class="columns is-centered has-text-left">
      <div class="column is-2">
        <div class="field">
          <div class="field-body">
            <div class="field">
              <b-field label="Price">
                <b-numberinput
                  :value="getLocalPrice"
                  @input="setLocalPrice"
                  step="0.01"
                  :disabled="isDisabled"
                >
                </b-numberinput>
              </b-field>
            </div>
          </div>
        </div>
      </div>
      <div class="column is-2">
        <div class="field">
          <div class="field-body">
            <div class="field">
              <b-field label="Tax">
                <!-- <b-checkbox :disabled="isDisabled" v-model="vLocalTax" @input="setLocalTax">
                  {{ getLocalTax }}
                </b-checkbox> -->
                <b-numberinput
                  :value="getLocalTax"
                  @input="setLocalTax"
                  step="0.01"
                  :disabled="isDisabled"
                >
                </b-numberinput>
              </b-field>
            </div>
          </div>
        </div>
      </div>
      <div class="column is-2">
        <div class="field">
          <div class="field-body">
            <div class="field">
              <b-field label="Quantity">
                <b-numberinput
                  :value="parseInt(localItem.quantity)"
                  @input="setLocalQuantity"
                  min="1"
                  :disabled="isDisabled"
                >
                </b-numberinput>
              </b-field>
            </div>
          </div>
        </div>
      </div>
      <div class="column is-3">
        <div class="field">
          <b-field type="is-success" label="Item">
            <b-input
              :value="localItem.name"
              @input="setLocalItemName"
              :disabled="isDisabled"
            ></b-input>
          </b-field>
        </div>
      </div>
      <div class="column is-1">
        <div class="field">
          <b-field type="is-info" label="Edit">
            <b-button
              :icon-right="getRightIcon"
              @click="enableOrSave(localItem.id)"
            ></b-button>
          </b-field>
        </div>
      </div>
    </div>
  </div>
  <div v-else>
    <div class="columns is-centered has-text-left">
      <div class="column is-2">
        <div class="field">
          <div class="field-body">
            <div class="field">
              <b-field label="Price">
                <b-numberinput
                  :value="getPrice"
                  @input="setPrice"
                  step="0.01"
                  :disabled="isNewDisabled"
                >
                </b-numberinput>
              </b-field>
            </div>
          </div>
        </div>
      </div>
      <div class="column is-2">
        <div class="field">
          <div class="field-body">
            <div class="field">
              <b-field label="Tax">
                <!-- <b-checkbox :disabled="isNewDisabled" v-model="vTax" @input="setTax">
                  {{ getTax }}
                </b-checkbox> -->
                <b-numberinput
                  :value="getTax"
                  @input="setTax"
                  step="0.01"
                  :disabled="isNewDisabled"
                >
                </b-numberinput>
              </b-field>
            </div>
          </div>
        </div>
      </div>
      <div class="column is-2">
        <div class="field">
          <div class="field-body">
            <div class="field">
              <b-field label="Quantity">
                <b-numberinput
                  :value="parseInt(invoiceItemQuantity)"
                  @input="setQuantity"
                  min="1"
                  :disabled="isNewDisabled"
                ></b-numberinput>
              </b-field>
            </div>
          </div>
        </div>
      </div>
      <div class="column is-3">
        <div class="field">
          <div class="field-body">
            <div class="field">
              <b-field label="Item">
                <b-input
                  placeholder="Enter Item name"
                  :value="invoiceItemName"
                  @input="setInvoiceItemName"
                  :disabled="isNewDisabled"
                ></b-input>
              </b-field>
            </div>
          </div>
        </div>
      </div>
      <div class="column is-1">
        <div class="field">
          <b-field type="is-info" label="Save">
            <b-button icon-right="save" @click="addInvoiceItem"></b-button>
          </b-field>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { createSnackbar } from '@/common';
import { mapActions, mapState } from 'vuex';

export default {
  props: {
    item: Object,
  },
  data() {
    return {
      isDisabled: true,
      isNewDisabled: false,
      localItem: this.item,
    };
  },
  mounted() {
    [
      'setInvoiceItemQuantity',
      'setInvoiceItemPrice',
      'setInvoiceItemTax',
    ].forEach((attribute) => this[attribute](0));
    this.setInvoiceItemName('');
  },
  methods: {
    ...mapActions([
      'setInvoiceItemName',
      'findOrCreateInvoiceItem',
      'setInvoiceItemQuantity',
      'setInvoiceItemPrice',
      'setInvoiceItemTax',
      'removeInvoiceItem',
    ]),
    createSnackbar,
    setLocalItemName(e) {
      this.localItem.name = e;
    },
    setPrice(e) {
      this.setInvoiceItemPrice(parseFloat(Math.round(e * 100)).toFixed(0));
    },
    setLocalPrice(e) {
      this.localItem.price = parseFloat(Math.round(e * 100)).toFixed(0);
    },
    setTax(e) {
      this.setInvoiceItemTax(e * 100);
      // if (e) {
      //   const tax = (this.invoiceItemPrice - (this.invoiceItemPrice / 1.2)) / 100;
      //   this.setInvoiceItemTax(parseFloat(Math.round(tax * 100)).toFixed(0));
      // } else {
      //   this.setInvoiceItemTax(0);
      // }
    },
    setLocalTax(e) {
      this.localItem.tax(e * 100);
      // if (e) {
      //   const tax = (this.localItem.price - (this.localItem.price / 1.2)) / 100;
      //   this.localItem.tax = parseFloat(Math.round(tax * 100)).toFixed(0);
      // } else {
      //   this.localItem.tax = 0;
      // }
    },
    setQuantity(e) {
      this.setInvoiceItemQuantity(Number(e));
    },
    setLocalQuantity(e) {
      this.localItem.quantity = Number(e);
    },
    async enableOrSave(itemId) {
      if (this.isDisabled) {
        this.isDisabled = false;
      } else {
        await this.editInvoiceItem(itemId);
        this.isDisabled = true;
      }
    },
    async editInvoiceItem(itemId) {
      try {
        this.localItem.id = itemId;
        await this.findOrCreateInvoiceItem(this.localItem);
      } catch (error) {
        this.createSnackbar(
          'Item details cannot be changed. Kindly check item details again.',
        );
      }
    },
    resetLocalItem() {
      [
        'setInvoiceItemQuantity',
        'setInvoiceItemPrice',
        'setInvoiceItemTax',
      ].forEach((attribute) => this[attribute](0));
      this.setInvoiceItemName('');
    },
    async addInvoiceItem() {
      try {
        await this.findOrCreateInvoiceItem();
        this.resetLocalItem();
      } catch (error) {
        this.createSnackbar(
          'Item cannot be added. Kindly check item details again.',
        );
      }
    },
  },
  computed: {
    ...mapState({
      invoiceItemName: ({ invoiceDetail: { invoiceItemName } }) => invoiceItemName,
      invoiceItemQuantity: ({ invoiceDetail: { invoiceItemQuantity } }) => invoiceItemQuantity,
      invoiceItemPrice: ({ invoiceDetail: { invoiceItemPrice } }) => invoiceItemPrice,
      invoiceItemTax: ({ invoiceDetail: { invoiceItemTax } }) => invoiceItemTax,
    }),
    getPrice() {
      return this.invoiceItemPrice / 100;
    },
    getLocalPrice() {
      return this.localItem.price / 100;
    },
    getTax() {
      return this.invoiceItemTax / 100;
    },
    getLocalTax() {
      return this.localItem.tax / 100;
    },
    checkUpdatedInvoiceItems() {
      const tax = (this.localItem.price - (this.localItem.price / 1.2)) / 100;
      return this.localItem.tax === tax;
    },
    checkNewInvoiceItems() {
      const tax = (this.invoiceItemPrice - (this.invoiceItemPrice / 1.2)) / 100;
      return this.invoiceItemTax === tax;
    },
    getRightIcon() {
      if (this.isDisabled) {
        return 'pen';
      }
      return 'save';
    },
  },
};
</script>
