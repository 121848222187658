<template>
    <b-field id="lead-search">
      <b-autocomplete
        :data="data"
        placeholder="Search charges by email"
        type="search"
        :loading="isFetching"
        @typing="getAsyncData"
      >
        <template slot-scope="chargeProps">
          <div class="media" id="lead-search-results">
            <div class="media-content">
              {{chargeProps.option.Payments.length > 1 ? 'Group Book' :
                `
                ${!chargeProps.option.Payments[0].Order ? 'No'
                : chargeProps.option.Payments[0].Order.User.firstName}
                ${!chargeProps.option.Payments[0].Order ? 'Name' :
                 chargeProps.option.Payments[0].Order.User.lastName}`
              }}
              <span class="is-pulled-right">
                <strong>{{ chargeProps.option.invoiceNumber }}</strong>
              </span>
              <br />
              <small>
                <strong>Email:</strong> {{ chargeProps.option.email }}
                <strong>Amount:</strong> £{{ chargeProps.option.amount / 100 }}
                <span class="is-pulled-right">
                  {{ new Date(chargeProps.option.createdAt).toDateString() }}
              </span>
              </small>
            </div>
          </div>
        </template>
        <template slot="footer">
        </template>
        <template v-if="isFetching" slot="empty"> Searching... </template>
        <template v-else slot="empty"> No result found </template>
      </b-autocomplete>
    </b-field>
  </template>

<script>
import debounce from 'lodash/debounce';
import { mapGetters } from 'vuex';
import { baseUrl } from '@/common';

export default {
  data() {
    return {
      data: [],
      selected: null,
      isFetching: false,
    };
  },
  computed: {
    ...mapGetters(['getCredentialString']),
  },
  methods: {
    searchKey(name) {
      return fetch(`${baseUrl}/charge/search?key=${name}`, {
        headers: {
          Authorization: this.getCredentialString,
        },
      })
        .then((response) => {
          if (response.status === 204 || response.status === 400) {
            throw new Error('No results found');
          }

          return response;
        })
        .then((response) => response.json());
    },
    getAsyncData: debounce(function getAsyncData(name) {
      this.data = [];
      const trimmedSearchTerm = name.trim();
      if (!trimmedSearchTerm.length || trimmedSearchTerm.length < 3) {
        return;
      }
      this.isFetching = true;
      this.searchKey(trimmedSearchTerm)
        .then(({ data }) => {
          data.charges.forEach((item) => this.data.push(item));
        })
        .catch(() => {
          this.data = [];
        })
        .finally(() => {
          this.isFetching = false;
        });
    }, 1200),
  },
};
</script>

  <style scoped>
  section {
    margin-top: 2.5em;
  }
  .mr-4 {
    margin-right: 2em;
  }

  .field {
    min-width: 500px;
  }
  </style>
