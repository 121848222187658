<template>
  <section class="section">
    <div v-if="invoiceId">
      <h1 class="title">Invoice has been Generated</h1>
    </div>
    <div v-if="!invoiceId">
      <div class="container">
        <div class="columns is-centered">
          <div class="column is-three-quarters">
            <h1 class="title">Invoice Details</h1>
            <h2 class="subtitle">Please enter information as per the order.</h2>
          </div>
        </div>
        <div class="columns is-centered">
          <div class="column is-three-quarters">
            <div class="title has-text-left">
              Amount: £<span>{{ charge.amount / 100 }}</span>
            </div>
          </div>
        </div>
        <div>
          <div class="columns is-centered">
            <div class="column is-three-quarters">
              <div class="field">
                <b-field label="Invoice To">
                  <b-input
                    :value="invoiceeName"
                    @input="updateName"
                  ></b-input>
                </b-field>
              </div>
            </div>
          </div>
          <div class="columns is-centered">
            <div class="column is-three-quarters">
              <table class="table is-fullwidth">
                <thead>
                  <td>Net Total</td>
                  <td>Net Tax</td>
                  <td>Gross Total</td>
                </thead>
            <tbody>
              <th>
                £{{getTotalNetPriceByItems}}
              </th>
              <th>
                £{{getTotalTaxByItems}}
              </th>
              <th>
                £{{getTotalPriceByItems}}
              </th>
            </tbody>
          </table>
            </div>
          </div>
          <div v-for="(item, index) in invoiceItems"
            :key="index"
          >
            <InvoiceItem :item="item" />
          </div>
        </div>
        <hr/>
        <div v-for="(item, index) in newItems" :key="index">
          <InvoiceItem :item="item" />
        </div>
      </div>
      <div class="field is-horizontal mt-5">
        <b-button expanded type="is-success" @click="generateInvoice">
          Generate New Invoice
        </b-button>
      </div>
    </div>
  </section>
</template>

<script>
import { mapActions, mapState } from 'vuex';
import { createSnackbar } from '@/common';
import InvoiceItem from './InvoiceItem.vue';

const emptyItem = {
  name: '',
  price: 0,
  quantity: 0,
  tax: 0,
};
export default {
  name: 'Invoice',
  props: {
    charge: Object,
  },
  data() {
    return {
      invoiceCreated: false,
      newItems: [emptyItem],
    };
  },
  mounted() {
    const user = this.charge.Payments[0].Order.User;
    const invoiceeName = `${user.title} ${user.firstName} ${user.lastName}`;
    this.updateName(invoiceeName);
  },
  components: {
    InvoiceItem,
  },
  methods: {
    ...mapActions(['setOrderId', 'getOrder', 'setInvoiceeName', 'findOrCreateInvoiceItem', 'createInvoice']),
    createSnackbar,
    updateName(e) {
      this.setInvoiceeName(e);
    },
    async generateInvoice() {
      window.scrollTo(0, 0);
      if (this.getTotalPriceByItems !== this.charge.amount / 100) {
        return this.createSnackbar('Order amount not equal to invoice amount');
      }
      try {
        await this.createInvoice(this.charge.id);
        this.invoiceCreated = true;
        return this.createSnackbar('Invoice has been created', true);
      } catch (error) {
        console.error('Error while adding invoice', error);
        return this.createSnackbar(error.message);
      }
    },
  },
  computed: {
    ...mapState({
      order: ({ orderDetail: { order } }) => order,
      invoiceeName: ({ invoiceDetail: { invoiceeName } }) => invoiceeName,
      invoiceId: ({ invoiceDetail: { invoiceId } }) => invoiceId,
      invoiceItems: ({ invoiceDetail: { invoiceItems } }) => invoiceItems,
    }),
    getTotalPriceByItems() {
      return this.invoiceItems.reduce(
        (acc, item) => acc + Number(item.price) * item.quantity, 0,
      ) / 100;
    },
    getTotalNetPriceByItems() {
      return this.invoiceItems.reduce(
        (acc, item) => acc + (Number(item.price) - Number(item.tax)) * item.quantity, 0,
      ) / 100;
    },
    getTotalTaxByItems() {
      return this.invoiceItems.reduce(
        (acc, item) => acc + (Number(item.tax) * item.quantity), 0,
      ) / 100;
    },
    normalizeOrderItems() {
      const items = [];
      if (this.order.OnlineCourses && this.order.OnlineCourses.length) {
        items.push({
          name: 'CSCS Green Card Training(Online)',
          price: 18000,
          quantity: 1,
          tax: 3000,
        });
      }
      if (this.order.OfflineCourseSeats && this.order.OfflineCourseSeats.length) {
        items.push({
          name: 'CSCS Green Card Training(Offline)',
          price: 18000,
          quantity: 1,
          tax: 3000,
        });
      }
      if (this.order.Tests && this.order.Tests.length) {
        items.push({
          name: 'CITB Health, Safety and Environment Test',
          price: 4500,
          quantity: 1,
          tax: 0,
        });
      }
      if (this.order.Cards && this.order.Cards.length) {
        items.push({
          name: 'CSCS Card',
          price: 6500,
          quantity: 1,
          tax: 1083,
        });
      }
      return items;
    },
  },
};
</script>
