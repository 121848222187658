<template>
    <section>
      <b-tooltip position="is-bottom" label="Generate Invoice">
        <b-button
            icon-pack="fa"
            icon-left="receipt"
            class="mx-2"
            @click="isComponentModalActive = true"
        ></b-button>
      </b-tooltip>

        <b-modal
            v-model="isComponentModalActive"
            trap-focus
            full-screen
            :destroy-on-hide="true"
            aria-role="dialog"
            aria-label="PDF Viewer"
            aria-modal>
            <template #default>
                  <Invoice :charge="charge" />
            </template>
        </b-modal>
    </section>
</template>

<script>
import Invoice from '@/components/Forms/Invoice/Invoice.vue';

export default {
  props: {
    charge: Object,
  },
  components: {
    Invoice,
  },
  data() {
    return {
      isComponentModalActive: false,
    };
  },
};
</script>
