<template>
  <div class="mt-4">
    <div class="columns is-centered">
        <div class="column is-half">
          <nav class="level">
    <div class="level-item has-text-centered">
      <div>
        <p class="heading">Page</p>
        <p class="title">{{ page }}</p>
      </div>
    </div>
    <div class="level-item has-text-left">
      <b-field>
        <b-radio-button expanded :native-value="false" v-model="isINVED" @input="updateINVED"
          type="is-success">
            <b-icon icon="check"></b-icon>
            <span>All</span>
        </b-radio-button>
        <b-radio-button expanded :native-value="true" v-model="isINVED" @input="updateINVED"
            type="is-danger">
              <b-icon icon="close"></b-icon>
              <span>INVED</span>
          </b-radio-button>

      </b-field>
    </div>
    <div class="level-item">
      <div>
        <b-datepicker
          @input="updateMinDate"
          size="is-small"
          placeholder="Start Date"
          icon="calendar-today"
          :icon-right="selected ? 'close-circle' : ''"
          trap-focus>
      </b-datepicker>
      To
      <b-datepicker
          @input="updateMaxDate"
          :min-date="new Date(minDate)"
          size="is-small"
          placeholder="End Date"
          icon="calendar-today"
          :icon-right="selected ? 'close-circle' : ''"
          trap-focus>
      </b-datepicker>
    </div>
    </div>
    <div class="level-item">
      <b-button @click="downloadData" type="is-info">Download</b-button>
    </div>
  </nav>
        </div>
        <div class="column is-half">
          <Search class="mt-3" />
        </div>
        </div>
    <div class="columns is-centered">
        <div v-if="isLoading">
          <Loading />
        </div>
        <div class="column is-full" v-else>
          <table v-if="!isLoading" class="table is-fullwidth is-hoverable">
            <thead>
              <tr>
                <th>Amount</th>
                <th>Creator</th>
                <th>Status</th>
                <th>Name</th>
                <th>Email</th>
                <th>Date</th>
                <th>Invoice Number</th>
                <th>Invoice</th>
              </tr>
            </thead>
            <tbody v-if="charges.length">
              <tr
                v-for="charge in charges" :key="charge.id"
              >
                <th class="has-text-weight-bold">
                    £{{ charge.amount/100 }}
                </th>
                <th
                v-if="charge.Payments.length > 0 &&
                charge.Payments[0].Order &&
                charge.Payments[0].Order.agentId"
                >
                    {{ getAgentById(charge.Payments[0].Order.agentId) }}
                </th>
                <th v-else>N/A</th>
                <th>
                    {{ charge.status }}
                </th>
                <td
                v-if="charge.Payments.length > 0 &&
                 charge.Payments[0].Order &&
                charge.Payments[0].Order.User"
                @click="navigateToCandidate(charge.Payments[0].Order.userId)"
                >
                    {{ charge.Payments[0].Order.User.firstName }}
                    {{ charge.Payments[0].Order.User.lastName }}
                </td>
                <td v-else>N/A</td>
                <td>{{ charge.email }}</td>
                <td>{{ getHumanDate(charge.createdAt, true) }}</td>
                <td>
                    {{ charge.invoiceNumber }}
                </td>
                <td v-if="charge.Invoices.length">
                  <b-dropdown aria-role="list">
                    <template #trigger="{ active }">
                        <b-button
                            label="Invoice"
                            type="is-info"
                            :icon-right="active ? 'caret-up' : 'caret-down'"
                            />
                    </template>

                    <b-dropdown-item
                      aria-role="listitem"
                      tag="a"
                      v-for="(invoice, index) in charge.Invoices"
                      :href="`${baseUrl}/invoice/${invoice.id}`"
                      :key="index"
                    >
                      {{ invoice.invoiceeName}}
                    </b-dropdown-item>
                </b-dropdown>
                </td>
                <td>
                  <InvoiceModal :charge="charge" />
                </td>
              </tr>
            </tbody>
          </table>
          <div class="is-flex-direction-row">
            <b-button
              class="is-justify-content-flex-start"
              @click="decrementPage"
              :disabled="page === 1"
              >Prev</b-button
            >
            <b-button
              class="is-justify-content-flex-start"
              @click="
                () => {
                  updateChargesViewPage(1);
                  queryCharges();
                }
              "
              >1</b-button
            >
            <b-button
              class="is-justify-content-flex-start"
              @click="
                () => {
                  updateChargesViewPage(5);
                  queryCharges();
                }
              "
              >5</b-button
            >
            <b-button
              class="is-justify-content-flex-start"
              @click="
                () => {
                  updateChargesViewPage(10);
                  queryCharges();
                }
              "
              >10</b-button
            >
            <b-button
              class="is-justify-content-flex-start"
              @click="
                () => {
                  updateChargesViewPage(20);
                  queryCharges();
                }
              "
              >20</b-button
            >
            <b-button
              class="is-justify-content-flex-start"
              @click="
                () => {
                  updateChargesViewPage(50);
                  queryCharges();
                }
              "
              >50</b-button
            >
            <b-button
              class="is-justify-content-flex-start"
              @click="
                () => {
                  updateChargesViewPage(75);
                  queryCharges();
                }
              "
              >75</b-button
            >
            <b-button class="is-justify-content-flex-end" @click="incrementPage"
              >Next</b-button
            >
            <div class="columns is-centered">
              <div class="column is-4">
                <b-field>
              <b-numberinput
               :value="page"
               @input="updateAndQueryCharges"
               min="1"
              ></b-numberinput>
            </b-field>
              </div>
            </div>
          </div>
        </div>
    </div>
  </div>
</template>

<script>
import { mapState, mapActions } from 'vuex';
import Loading from '@/components/Loading.vue';
import InvoiceModal from '@/components/InvoiceModal.vue';
import Search from '@/components/ChargeList/Search.vue';

import {
  baseUrl, navigateToOrder, getAgentById,
  generatedInvoiceDownloadLink, navigateToCandidate, getHumanDate,
} from '@/common';

export default {
  name: 'ChargeList',
  data: () => ({
    isINVED: false,
    selected: null,
    baseUrl,
    isLoading: true,
  }),
  async mounted() {
    try {
      await this.queryCharges();
    } catch (error) {
      console.error(error);
      this.$router.push({ name: 'Home' });
    } finally {
      this.isLoading = false;
    }
    return null;
  },
  components: {
    Loading,
    InvoiceModal,
    Search,
  },
  methods: {
    ...mapActions([
      'queryCharges',
      'updateChargesViewPage',
      'setUserId',
      'setRequiredFilter',
      'setCHMinDate',
      'setCHMaxDate',
    ]),
    navigateToOrder,
    getAgentById,
    generatedInvoiceDownloadLink,
    navigateToCandidate,
    getHumanDate,
    downloadData() {
      return this.queryCharges(true);
    },
    updateMinDate(minDate) {
      this.setCHMinDate(minDate);
    },
    updateMaxDate(maxDate) {
      this.setCHMaxDate(maxDate);
    },
    async decrementPage() {
      this.updateChargesViewPage('DECREMENT');
      this.isLoading = true;
      try {
        await this.queryCharges();
      } catch (error) {
        this.$router.push({ name: 'Home' });
        throw error;
      } finally {
        this.isLoading = false;
      }
    },
    async incrementPage() {
      this.updateChargesViewPage('INCREMENT');
      this.isLoading = true;
      try {
        await this.queryCharges();
      } catch (error) {
        this.$router.push({ name: 'Home' });
        throw error;
      } finally {
        this.isLoading = false;
      }
    },
    updateINVED(payload) {
      this.setRequiredFilter(payload);
      return this.queryCharges();
    },
    updateAndQueryCharges(page) {
      this.updateChargesViewPage(page);
      this.queryCharges();
    },
  },
  computed: {
    ...mapState({
      allAgents: ({ agentData: { allAgents } }) => allAgents,
      charges: ({ chargesView: { charges } }) => charges,
      page: ({ chargesView: { page } }) => page,
      minDate: ({ chargesView: { minDate } }) => minDate,
    }),
  },
};
</script>
<style scoped>

table {
    cursor: pointer;
}
</style>
